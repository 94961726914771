.section {
  &-list {
    @include items-list;
    width: 100%;
    margin: 0 auto;
    position: relative;
    padding: 0 10px;

    &.vip-page-list {
      padding: 10px;
    }

    &--horizontal-scroll {
      display: flex;
      flex-wrap: nowrap;
      padding: 0 10px 11px;
      overflow-x: scroll;
      position: relative;
      -webkit-overflow-scrolling: touch;
      scrollbar-width: none; // hides scrollbar on firefox
      box-sizing: border-box;

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
    }

    &-bookmarks {
      justify-content: flex-start;
      margin: 0 auto;
      padding: 15px 15px 20px;
      background-color: #fff;
      box-sizing: border-box;

      &__empty {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 85vh;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        text-align: center;
        color: #98918a;

        &__text {
          margin-top: 30px;
        }
      }
    }
  }
}
