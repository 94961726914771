.auth {
  padding: 0;
  text-align: center;
  position: relative;
  overflow-y: auto;
  box-sizing: border-box;

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  &-container {
    width: 100%;
    padding: 40px;
    text-align: center;
  }

  &-modal {
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    transform: none;
    flex-direction: column;
    border-radius: 0;
  }

  &__close-btn {
    display: inline-block;
    position: fixed;
    width: 35px;
    height: 35px;
    padding: 0;
    margin: 0;
    border-radius: 50%;
    box-sizing: border-box;
    color: transparent;
    font-size: 0;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
    top: 30px;
    right: 15px;

    &::before,
    &::after {
      position: absolute;
      left: 16px;
      top: 8px;
      content: ' ';
      height: 18px;
      width: 2px;
      background-color: #98918a;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }

  &__logo {
    margin: 0 auto 30px;
    width: 123px;
    height: 28px;
    background: transparent url('/static/images/icon-logo-black.svg') no-repeat;
    background-size: contain;
  }

  &__promo {
    margin-bottom: 20px;
    font-size: 14px;
    color: #98918a;
    line-height: 17px;
  }

  &__confirmation {
    text-align: center;
    color: #98918a;
    position: fixed;
    left: 40px;
    right: 40px;
    font-size: 12px;
    bottom: 20px;

    a {
      color: #98918a;
      text-decoration: underline;
    }

    @media screen and (max-height: 480px) {
      position: static;
      padding: 20px 40px;
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 7px;
    font-size: 15px;
    text-decoration: none;
    cursor: pointer;
    box-sizing: border-box;
    outline: none;

    &--default {
      background-color: #4264fd;
      border: 1px solid #4264fd;
      color: #fff;
    }

    &--email {
      color: #4264fd;
      border: 1px solid #4264fd;
      background-color: transparent;
    }

    & + & {
      margin-top: 15px;
    }
  }

  &__btn {
    height: 48px;
  }

  &__link {
    display: inline-block;
    font-size: 15px;
    line-height: 18px;
    color: #4264fd;
    text-decoration: underline;

    &--bottom {
      margin-top: 20px;
    }
  }
}
