.lotriver-top-banner {
  margin-bottom: 10px;

  .main-content &:first-child {
    margin-bottom: 0;
  }

  &--rows {
    margin-top: -8px;
  }

  &:empty {
    display: none;
  }

  a img {
    width: 100%;
    max-width: 480px !important;
    object-fit: cover;
  }
}

.lotriver-bottom-banner {
  width: 100%;
  margin-bottom: 10px;

  &:empty {
    display: none;
  }

  &.content-banner {
    overflow: hidden;

    @media screen and (min-width: 480px) {
      display: none;
    }
  }
}

.google-buds-bottom-banner {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
