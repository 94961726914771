$textfield-border-color: #eae5e1;
$textfield-background-color: #f8f8f8;
$icon-size: 24px;
$padding: 10px;
$title-color: #98918a;
$icon-path: '../../../public/static/images/new_search/icons/icon-pin.svg';

.search-bar {
  position: relative;
  border-radius: 14px;
  background-color: $textfield-background-color;
  display: flex;
  align-items: center;
  padding-left: calc(#{$icon-size} + #{$padding} + 10px);
  padding-right: 35px;
  justify-content: space-between;
  height: 48px;

  &__wrapper {
    margin-right: 10px;
    border: 1px solid $textfield-border-color;
    border-radius: 14px;
    overflow: hidden;
    position: relative;
    width: calc(100% - 48px - 10px);
    height: 48px;
  }

  &__reset {
    display: flex;
    position: absolute;
    width: 40px;
    background-color: $textfield-background-color;
    right: 0;
    top: 0;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &__form {
    width: 100%;
    border-bottom: 0.5px solid $colour-grey-wild-sand;
  }

  &::before {
    content: '';
    background-image: url($icon-path);
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    left: $padding;
    top: 50%;
    transform: translateY(-50%);
    width: $icon-size;
    height: $icon-size;
  }

  input[type='text'] {
    border-style: none;
    width: 100%;
    color: $title-color;
    padding: $padding 0;
    background: transparent;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:focus {
      outline: none;
    }
  }

  &-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 5px;
  }

  &__filters {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 14px;
    background-color: #4264fd;
    padding: 10px;

    &__float {
      position: fixed;
      right: 5px;
      bottom: 95px;
      z-index: 2;
      display: flex;
      color: #fff;
      font-size: 15px;
      height: 38px;
      padding: 10px 15px;
      border-radius: 20px;
      background-color: #4264fd;
      opacity: 0;
      transition: opacity 0.4s ease;
    }
  }
}
