$primary-color: #936447;

.init-slider {
  z-index: 0 !important;
}

.item-card {
  width: 173px;
  position: relative;
  vertical-align: top;
  z-index: 1;
  overflow: hidden;
  margin: 0 0 $padding;
  text-decoration: none;
  border-radius: 7px;
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.05);
  background-color: #fff;

  &_vipped,
  &_featured {
    .products-paid {
      display: flex;
      z-index: 1;
      position: absolute;
      bottom: 10px;
      right: 10px;
      background-color: #fff;
      padding: 5px;
      border-radius: 5px;

      span {
        width: 13px;
        height: 10px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        transform: translateZ(0);
      }

      .featured-icon {
        background-image: url('/static/images/featured-icon.svg');
      }

      .vipped-icon {
        background-image: url('/static/images/vipped-icon.svg');
      }
    }

    .featured-icon + .vipped-icon {
      margin-left: 2px;
    }
  }

  &-link {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: inline-flex;

    &-zindex {
      z-index: 3;
    }
  }

  &-preview {
    position: relative;
    background-color: #bbb;
    background-image: url('/static/images/loading-spinner.gif');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 30px;
    z-index: 2;
    padding-top: 74.29%;

    .products-label {
      position: absolute;
      bottom: 10px;
      left: 10px;
      color: #fff;
      font-size: 11px;
      text-align: center;
      line-height: 14px;
      padding: 2px 6px;
      font-weight: 500;
      box-sizing: border-box;
      border-radius: 4px;
      z-index: 1;

      &--residence {
        background-color: #af8329;
      }

      &--agency {
        background-color: #4264fd;
      }
    }
  }

  &-preview-img {
    min-height: auto;
    height: 100%;
    min-width: auto;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  &-price {
    display: inline-block;
    padding: 7px 8px 7px 10px;
    font-weight: bold;
    font-size: 15px;
    line-height: 17px;
    color: #2f1f19;
    white-space: nowrap;

    &-cur,
    &-per {
      position: relative;
    }

    &-val {
      margin-right: 5px;
    }
  }

  &-params {
    position: relative;
    display: block;
    text-decoration: none;
    color: #444;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    padding: 31px 15px 5px 10px;
    box-sizing: border-box;
    overflow: hidden;
    z-index: -1;

    &-location {
      @include ellipsis();
      font-size: 14px;
      font-weight: 400;
      line-height: 1.21;
      letter-spacing: 0.1px;
      color: #2f1f19;
      margin-bottom: 3px;
    }

    &-name {
      display: flex;
      list-style: none;
      padding: 0;
      margin: 0;
      overflow: hidden;
      white-space: nowrap;
      font-size: 14px;
      line-height: 1.21;
      font-weight: 400;
      color: #2f1f19;

      li {
        // stylelint-disable max-nesting-depth
        font-size: 14px;
        font-weight: 500;
        position: relative;
        color: #2f1f19;

        &:last-of-type {
          @include ellipsis;
        }

        &:not(:last-child) {
          &::after {
            content: ', ';
            display: inline-block;
            margin-right: 1px;
          }
        }
      }
    }
  }

  &-icons-section {
    position: absolute;
    top: 5px;
    left: 1px;
    z-index: 3;
    margin: 2px 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div {
      width: 21px;
      height: 21px;
      background-size: cover;
      display: inline-block;
      margin: 2px 0 2px 4px;
      z-index: 2;
      position: relative;
      background-position: 0 0;
      transform: translateZ(0);
    }

    // stylelint-disable-next-line
    .bill_of_sale {
      background-image: url('/static/images/icon-docs.svg');
    }

    .mortgage {
      background-image: url('/static/images/mortgage-icon.svg');
    }

    .repair {
      background-image: url('/static/images/repair-icon.svg');
    }
  }

  &-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #98918a;
    font-size: 12px;
    width: 100%;
    padding: 0 11px 14px;
    line-height: 12px;
    box-sizing: border-box;

    .city_when {
      @include ellipsis;
    }
  }

  .section-list--horizontal-scroll & {
    min-width: 48.5%;
    margin-right: 2% !important;
    margin-bottom: 0;

    &:last-child {
      margin-right: 0 !important;
    }

    @media screen and (max-width: 315px) {
      min-width: 100%;
      margin-right: 1% !important;
    }

    @media screen and (min-width: 520px) {
      min-width: 32.5%;
      margin-right: 1.2% !important;
    }

    @media screen and (min-width: 688px) {
      min-width: 24%;
      margin-right: 1.3% !important;
    }

    @media screen and (min-width: 864px) {
      min-width: 19%;
      margin-right: 1.2% !important;
    }

    @media screen and (min-width: 1023px) {
      min-width: 16%;
      margin-right: 0.66% !important;
    }
  }
}

.abs_block {
  position: absolute;
  top: 3px;
  left: 0;
  z-index: 11;
}

/* FixMe: do it with mixins for media queries */
@media screen and (min-width: 1023px) {
  @include item-card(16%, 0.66%, 6);
}

@media screen and (min-width: 864px) and (max-width: 1022px) {
  @include item-card(19%, 1.2%, 5);

  .section-list_hide-excess-items {
    .item-card:nth-of-type(n + 6) {
      display: none;
    }
  }
}

@media screen and (min-width: 688px) and (max-width: 863px) {
  @include item-card(24%, 1.3%, 4);

  .section-list_hide-excess-items {
    .item-card:nth-of-type(n + 5) {
      display: none;
    }
  }
}

@media screen and (min-width: 520px) and (max-width: 687px) {
  @include item-card(32.5%, 1.2%, 3);

  .section-list_hide-excess-items {
    .item-card:nth-of-type(n + 4) {
      display: none;
    }
  }
}

@media screen and (min-width: 375px) and (max-width: 519px) {
  @include item-card(48.5%, 2.9%, 2);

  .section-list_hide-excess-items {
    .item-card:nth-of-type(n + 3) {
      display: none;
    }
  }
}

@media screen and (min-width: 316px) and (max-width: 374px) {
  @include item-card(48.5%, 1%, 2);

  .section-list_hide-excess-items {
    .item-card:nth-of-type(n + 3) {
      display: none;
    }
  }
}

@media screen and (max-width: 315px) {
  .item-card {
    width: 100%;
  }
}
