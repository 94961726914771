.modal {
  &__content {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100.1%; // fix bug with url bar in Chrome Mobile
    overflow-y: scroll;
    background-color: $colour-white;

    &--alert {
      position: relative;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      height: fit-content;
      width: 80vw;
      border-radius: 7px;
      background: #fff;
      overflow-y: hidden;
    }

    &--contacts-info,
    &--report,
    &--electronic-banners {
      top: unset;
      position: fixed;
      overflow-y: hidden;
      border-radius: 7px 7px 0 0;
      height: auto;
    }

    &--map {
      position: relative;
      height: auto;
    }

    &-params,
    &-apartments,
    &-contacts {
      position: absolute;
      height: auto;
      bottom: 0;
      top: auto;
      border-radius: 7px 7px 0 0;
    }

    &-params {
      height: 90%;
    }

    &--side-menu {
      background: #f6f4f1;
      overflow-y: auto;

      &-container {
        height: 600px;
      }
    }
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    font-family: $helvetica;
    background-color: $colour-white;
    // stylelint-disable-next-line
    -webkit-backface-visibility: hidden;

    &--alert {
      background: rgba(0, 0, 0, 0.4);
    }

    &--map,
    &-params,
    &-contacts,
    &-apartments {
      background-color: rgba(0, 0, 0, 0.8);
    }

    &--contacts-info,
    &--report,
    &--electronic-banners {
      background: rgba(0, 0, 0, 0.4);
      z-index: 100001;
    }

    &--side-menu {
      z-index: 96;
    }

    &--calc-info {
      z-index: 100001;
    }
  }

  &__portal {
    -webkit-overflow-scrolling: touch;
  }

  &__body--open {
    overflow: hidden;
  }
}
